import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { API_TEMPLATE, API_CHECKLIST } from "src/constants/apiUrls";
import { apiGetData } from "src/services/api.service";

const initialState = {
    totalCount: 0,
    templates : [],
    filteredTemplates: [],
    templateTasks: [],
    selectedTemplate: '',
    projectStartDate: moment(new Date()),
    checkListTemplates: [],
    checklistCount : 0,
    taskListOption: [],
    error : null,
    checklistTemplateCount: 0
}

export const getTemplates = createAsyncThunk('@templates/getAll', async (params, thunkAPI) => {
  const url = `${API_TEMPLATE}`;
  try {
    const response = await apiGetData(url, params);
    const responseData = response.data?.response;
    return responseData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})


export const getChecklistTemplate = createAsyncThunk(
  "@common/getChecklistTemplate",
  async (params, thunkAPI) => {
  const url = `${API_CHECKLIST}`;
    try {
      const response = await apiGetData(url, params);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers : {
    setFilteredTemplates: (state, action) => {
      state.filteredTemplates = action.payload;
    },
    setTemplateTasks: (state, action) => {
      const allTasks = action.payload?.map(category => category.tasks.map(task => Object({value: task._id || task.templateTaskNo, label: `${task.name} (${category.name})`}))).flat();
      state.taskListOption = allTasks;
      state.templateTasks = action.payload
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload
    },
    setProjectStartDate : (state, action) => {
      state.projectStartDate = action.payload
    },
    
    changeCategoryTitle: (state, action) => {
      const categoryArray = [...state.templateTasks];
      const {title, tasks, newTitle} = action.payload;
      const updated = categoryArray.map(cat => cat.name === title ? ({...cat, name: newTitle, tasks}) : cat);
      state.templateTasks = updated;
    }
  },
  extraReducers: (builder) => {
      builder.addCase(getTemplates.pending, (state) => {
          state.loading = "pending";
      })
      builder.addCase(getTemplates.fulfilled, (state, action) => {
          state.loading = "idle";
          state.templates = action.payload?.template;
          state.totalCount = action.payload?.totalCount;
      })
      builder.addCase(getTemplates.rejected, (state, action) => {
          state.loading = "idle";
          state.error = action.payload;
      })

      builder.addCase(getChecklistTemplate.pending, (state) => {
        state.loading = "pending";
      })
      builder.addCase(getChecklistTemplate.fulfilled, (state, action) => {
        state.loading = "idle";
        state.checkListTemplates = action.payload?.checklist;
        state.checklistTemplateCount = action.payload?.totalCount;
      })
      builder.addCase(getChecklistTemplate.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.payload;
      })
  }
})

// selectors
export const templateSelector = (state) => state.templates.templates;
export const checkListTemplateSelector = (state) => state.templates.checkListTemplates;
export const totalTemplateCount = (state) => state.templates.totalCount;
export const totalChecklistTemplateCount = (state) => state.templates.checklistTemplateCount;
export const filteredTemplatesSelector = (state) => state.templates.filteredTemplates;
export const templateTasksSelector = (state) => state.templates.templateTasks;
export const selectedTemplateSelector = (state) => state.templates.selectedTemplate;
export const projectStartDateSelector = (state) => state.templates.projectStartDate;
// export const projectStartDateSelector = (state) => state.templates.actualStartDate;

export const taskListOptionSelector = (state) => state.templates.taskListOption;

// actions
export const { setFilteredTemplates, setSelectedTemplate, setTemplateTasks, setProjectStartDate, changeCategoryTitle } = templatesSlice.actions;

export default templatesSlice.reducer;